import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { getSlashedLink } from "@bit/azheng.joshua-tree.bit-utils/getSlashedLink"

export default function JLink({ to, ...props }) {
  return <Link {...props} to={getSlashedLink(to)} />
}

JLink.propTypes = {
  to: PropTypes.string.isRequired
}
