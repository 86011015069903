import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import moment from "moment"
import classNames from "classnames"
import ImageMeta from "../ImageMeta"
import SocialIcon from "./SocialIcon"
import GeneralHours from "@bit/azheng.joshua-tree.general-hours"

export default class ComFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.toggleFooter = this.toggleFooter.bind(this)
  }

  toggleFooter() {}

  render() {
    var comfooterClass = classNames({
      "com-footer": true,
      "color-back": true
    })

    return (
      <StaticQuery
        query={graphql`
          query footerMyQuery {
            allDataJson(filter: { optionsItem: { eq: "siteVariables" } }) {
              nodes {
                siteItems {
                  locations {
                    location {
                      locationName
                      stateAbbr
                      googlePlaceLink
                      streetNumber
                      streetName
                      cityZip
                      cityName
                      stateName
                      layout
                      metaTitle
                      metaDescription
                      title
                      searchHeading
                      contactInfo {
                        email
                        phone
                      }
                      locationHours {
                        endTime
                        startTime
                        weekDay
                      }
                      socialAccounts {
                        socialAcount
                        socialAcountLink
                      }
                      extraLinks {
                        statements {
                          linkText
                          pageLink
                          title
                        }
                      }
                    }
                  }
                }
              }
            }
            allFooterJson {
              nodes {
                footerItems {
                  blurbText
                  mainProcedureDone
                }
              }
            }
            site {
              siteMetadata {
                fullPracticeName
              }
            }
          }
        `}
        render={data => {
          let siteMetadata = data.site.siteMetadata

          let locationOne =
            data.allDataJson.nodes[0].siteItems.locations[0].location

          let footerInfo = data.allFooterJson.nodes[0]

          const seoHiddenFooterLink =
            this.props.pageTitle ==
            "procedure/cosmetic-surgery-amarillo-tx/" ? (
              <p class="cosmetic" style={{ fontSize: 12 }}>
                <a
                  href="https://nuvolum.com/plastic-surgery-marketing/"
                  title="Cosmetic &amp; Plastic Surgery Marketing by Nuvolum.com"
                  target="_blank"
                  style={{ color: "#002431", fontWeight: "normal" }}>
                  Cosmetic &amp; Plastic Surgery Marketing
                </a>{" "}
                by Nuvolum
              </p>
            ) : (
              <></>
            )

          return (
            <footer
              id={this.props.pageTitle}
              className={comfooterClass + " " + this.props.pageTitle}>
              <div className="columns is-desktop footer-top">
                <div className="column is-12 footer-left">
                  <div className="columns is-centered is-vcentered">
                    <div className="column is-2"></div>
                    <div className="column is-4 footer-logo">
                      <ImageMeta
                        cloudName="nuvolum"
                        publicId="AOMS/DEV/logo-mark"
                        width="auto"
                        responsive></ImageMeta>
                    </div>
                    <div className="column is-2"></div>
                    <div className="column">
                      <h5>
                        {this.props.language === "es"
                          ? "Nuestro consultorio se esfuerza por brindar experiencias excepcionales a los pacientes."
                          : "Our practice strives to provide exceptional patient experiences."}
                      </h5>
                    </div>
                    <div className="column is-2"></div>
                  </div>
                </div>
                <div className="column footer-right">
                  <div className="columns footer-right-top">
                    <div className="column">
                      <h7>
                        {this.props.language === "es"
                          ? "Ubicación"
                          : "Location"}
                      </h7>
                      <p className="small footer-location-address">
                        <a
                          aria-label="open link in new browser tab"
                          target="_blank"
                          href={locationOne.googlePlaceLink}
                          title={locationOne.locationName}>
                          {locationOne.streetNumber + " "}
                          {locationOne.streetName}
                          <br />
                          {locationOne.cityName}
                          {", " + locationOne.stateAbbr + " "}
                          {locationOne.cityZip}
                        </a>
                      </p>
                    </div>
                    <div className="column">
                      <GeneralHours
                        className="is-centered"
                        footer={true}
                        locationHours={locationOne.locationHours}
                        language={this.props.language}
                      />
                    </div>
                  </div>
                  <div className="columns footer-right-bottom">
                    <div className="column">
                      <h7>
                        {this.props.language === "es"
                          ? "Charlemos"
                          : "Let's Chat"}
                      </h7>

                      <p className="small">
                        <a href={"tel:+" + locationOne.contactInfo.phone}>
                          {locationOne.contactInfo.phone}
                        </a>
                      </p>
                      <p className="small">
                        <a href={`mailto:${locationOne.contactInfo.email}`}>
                          {locationOne.contactInfo.email}
                        </a>
                      </p>
                      {locationOne.contactInfo.chatLink && (
                        <p className="small">
                          <Link
                            to={
                              this.props.language === "es"
                                ? "/contact-us-amarillo-tx/"
                                : "/contact-us/"
                            }>
                            {this.props.language === "es"
                              ? "Mandanos un Mensaje"
                              : locationOne.contactInfo.chatLink}
                          </Link>
                        </p>
                      )}
                    </div>
                    <div className="column footer-social">
                      <div className="column">
                        <h7 style={{ marginBottom: "8px" }}>
                          {this.props.language === "es" ? "Sociales" : "Social"}
                        </h7>
                      </div>
                      <div className="columns">
                        {locationOne.socialAccounts &&
                          locationOne.socialAccounts.map(item => (
                            <React.Fragment key={item.id}>
                              <p
                                className={`small social-icon ${item.socialAcount}`}>
                                <a
                                  aria-label="open link in new browser tab"
                                  href={item.socialAcountLink}
                                  title={`Follow us on ${item.socialAcount}`}
                                  target="_blank">
                                  <SocialIcon
                                    fillColor="#6d6e6f"
                                    socialAccount={item.socialAcount}
                                  />
                                </a>
                              </p>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-2"></div>
              </div>

              <div
                className="columns is-desktop footer-bottom"
                style={{ alignItems: "flex-end" }}>
                <div className="column is-4"></div>
                <div className="column is-7 sub-footer-copyright">
                  <p className="footer">
                    © {moment().format("YYYY")} {siteMetadata.fullPracticeName}.
                    All rights reserved.
                    {seoHiddenFooterLink}
                  </p>
                  <p
                    className="nuvo-brand footer"
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.language === "es"
                          ? 'Sitio Web y Producción de Video por <a href="https://nuvolum.com" target="_blank" title="Sitio Web y Producción de Video por Nuvolum">Nuvolum</a>'
                          : 'Website and Video Production by <a href="https://nuvolum.com" target="_blank" title="Website and Video Production by Nuvolum">Nuvolum</a>'
                    }}></p>
                  {this.props.language === "en" && (
                    <p className="nuvo-brand footer">
                      <a
                        href="https://getwuwta.com/"
                        title="WUWTA - The Patient Communication Platform That Delivers Superior Outcomes"
                        target="_blank">
                        Patient experience powered by WUWTA™
                      </a>
                    </p>
                  )}
                </div>
                <div className="column is-1"></div>
                <div className="column">
                  <div className="columns sub-footer-statements">
                    {this.props.language !== "es" &&
                      locationOne.extraLinks &&
                      locationOne.extraLinks[0].statements.map(item => (
                        <div key={item.id} className="column is-narrow">
                          <p className="footer">
                            <Link to={`/${item.pageLink}/`} title={item.title}>
                              {item.linkText}
                            </Link>
                          </p>
                        </div>
                      ))}
                    {this.props.language === "es" &&
                      locationOne.extraLinks &&
                      locationOne.extraLinks[0].statements.map(item => (
                        <React.Fragment key={item.id}>
                          <p className="footer">
                            <Link to={`/${item.pageLink}/`} title={item.title}>
                              {item.linkText}
                            </Link>
                          </p>
                        </React.Fragment>
                      ))}
                  </div>
                </div>
                <div className="column is-2"></div>
              </div>
              {/* <section className="section">
        <div className="container footer-super-sets">
            <div className="columns">
                <div className="columns footer-blurb">
                    <div><img src={"https://res.cloudinary.com/nuvolum/image/upload/v1/AOMS/DEV/"+"logo-mark.svg"}/></div>
                    <div className="footer-blurb-text"><h5>{this.props.language === "es" ? "Nuestro objetivo es proporcionar a cada paciente una experiencia notable y beneficiosa." : footerInfo.footerItems.blurbText}</h5></div>
                </div>
                <div className="column footer-sets">
                    <div className="columns footer-set-1">
                <div className="column">
                <h7>{this.props.language === "es" ? "Ubicación" : "Location"}</h7>
                <p className="small footer-location-address"><a target="_blank" href={locationOne.googlePlaceLink} title={locationOne.locationName}>{locationOne.streetNumber +" "}
                            {locationOne.streetName}<br />
                            {locationOne.cityName}
                            {", " +locationOne.stateAbbr+" "}
                            {locationOne.cityZip}</a></p>
                </div>
                        <GeneralHours className="is-8 is-centered" footer={true} locationHours={locationOne.locationHours} language={this.props.language} />
                    </div>
                    <div className="columns footer-set-2">
                        <div className="column is-16 footer-contact">
                            <div className="column"><h7>{this.props.language === "es" ? "Charlemos" : "Let's Chat"}</h7></div>
                            <div className="column">
                                <div><p className="small"><a href={"tel:+"+ locationOne.contactInfo.phone}>{locationOne.contactInfo.phone}</a></p></div>
                                <div><p className="small"><a href={`mailto:${locationOne.contactInfo.email}`}>{locationOne.contactInfo.email}</a></p></div>
                                <div><p className="small"><Link to={this.props.language === "es" ? "/contact-us-amarillo-tx/" : "/contact-us/"}>{this.props.language === "es" ? "Mandanos un Mensaje" : locationOne.contactInfo.chatLink}</Link></p></div>
                            </div>
                        </div>
                        <div className="column footer-social">
                            <div className="column"><h7>{this.props.language === "es" ? "Sociales" : "Social"}</h7></div>
                            <div className="columns">{locationOne.socialAccounts && locationOne.socialAccounts.map(item => (
                                    <React.Fragment key={item.id}>
                                    <p className={`small social-icon ${item.socialAcount}`}><a href={item.socialAcountLink} target="_blank">
                                      <SocialIcon fillColor="#70f82e" socialAccount={item.socialAcount} /></a></p>
                                </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container sub-footer">
            <div className="columns">
                <div className="column sub-footer-copyright">
                    <div>© {moment().format('YYYY')} {siteMetadata.fullPracticeName}. All rights reserved.</div>
                    <div className="nuvo-brand" dangerouslySetInnerHTML={{__html: this.props.language === "es" ? 'Sitio Web y Producción de Video por <a href="https://nuvolum.com" target="_blank" title="Sitio Web y Producción de Video por Nuvolum">Nuvolum</a>' : 'Website and Video Production by <a href="https://nuvolum.com" target="_blank" title="Website and Video Production by Nuvolum">Nuvolum</a>'}}></div>
                </div>
                <div className="columns sub-footer-statements">
                {this.props.language !== "es" && locationOne.extraLinks && locationOne.extraLinks[0].statements.map(item => (
                    <React.Fragment key={item.id}>
                    <p className="footer"><Link to={`/${item.pageLink}/`} title={item.title}>{item.linkText}</Link></p>
                </React.Fragment>
                ))}
                {this.props.language === "es" && locationOne.extraLinks && locationOne.extraLinks[0].statements.map(item => (
                    <React.Fragment key={item.id}>
                    <p className="footer"><Link to={`/es/${item.pageLink}/`} title={item.title}>{item.linkText}</Link></p>
                </React.Fragment>
                ))}
                </div>
            </div>
        </div>
    </section> */}
            </footer>
          )
        }}
      />
    )
  }
}
